import React from 'react';
import {
  ChakraProvider,
  Box,
  VStack,
  Heading,
  Text,
  Button,
  Link,
  useColorMode,
  useColorModeValue,
  IconButton,
  Flex,
  Spacer,
  Container,
  Badge,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
} from '@chakra-ui/react';
import { ColorModeScript } from '@chakra-ui/react'
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player/youtube';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import theme from './theme'; // Make sure to create this file as explained in the previous message

const MotionBox = motion(Box);

function App() {
  const { colorMode, toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue('blue.50', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'white');

  return (
    <Box minHeight="100vh" bg={bgColor} color={textColor}>
      <Container maxW="container.xl" py={8}>
        <Flex mb={8}>
          <Heading as="h1" size="xl">
          🇮🇳 wins!
          </Heading>
          <Spacer />
          <IconButton
            icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
            onClick={toggleColorMode}
            variant="ghost"
          />
        </Flex>

        <VStack spacing={12} align="stretch">
          <MotionBox
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <Heading className="text-4xl font-bold mb-4" textAlign="center">
              17 saalo ka comeback
            </Heading>
            <Text fontSize="xl" textAlign="center">
             Let's watch this moment together :D.  🇮🇳
            </Text>
          </MotionBox>

          <MotionBox
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <Box
              borderRadius="lg"
              overflow="hidden"
              boxShadow="2xl"
            >
              <ReactPlayer
              url="https://www.youtube.com/watch?v=xhwbInvcJB4"
              width="100%"
              height="400px"
              controls={false}
              light={true}
              playing={true}
            />
            </Box>
          </MotionBox>

          <StatGroup>
            <Stat>
              <StatLabel>Total Runs</StatLabel>
              <StatNumber>348</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Wickets Taken</StatLabel>
              <StatNumber>8</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Player of the Match</StatLabel>
              <StatNumber>Virat Kohli</StatNumber>
            </Stat>
          </StatGroup>

          <Stack direction={['column', 'row']} spacing={4} justify="center">
            <Button
              as={Link}
              href="https://www.youtube.com/watch?v=ky_bHY8BklI&pp=ygUaaW5kaWEgdnMgc291dGggYWZyaWNhIGVkaXQ%3D"
              colorScheme="blue"
              size="lg"
              leftIcon={<span>🎥</span>}
            >
              Watch Live Reaction
            </Button>
            <Button
              as={Link}
              href="https://www.cricbuzz.com/live-cricket-scores/87878/ind-vs-rsa-final-icc-mens-t20-world-cup-2024"
              colorScheme="green"
              size="lg"
              leftIcon={<span>📊</span>}
            >
              View Scorecard
            </Button>
          </Stack>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Match Highlights
            </Heading>
            <Text mb={4}>
              In short India ka comeback was OG💙
            </Text>
            <Stack direction={['column', 'row']} spacing={4}>
              <Badge colorScheme="purple" p={2} borderRadius="md">
                Stunning Play by Virat Kohli
              </Badge>
              <Badge colorScheme="orange" p={2} borderRadius="md">
                Player Tournament = Jasprit Bumrah
              </Badge>
              <Badge colorScheme="cyan" p={2} borderRadius="md">
                Match-winning Wickets by Bumrah and Surya
              </Badge>
            </Stack>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
}

function AppWrapper() {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <App />
    </ChakraProvider>
  );
}

export default AppWrapper;
